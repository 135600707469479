<template>
  <div style="width: 100%; overflow: hidden; position: relative" id="parent">
    <div class="left_tab_parent" :style="{ height: tab_height }">
      <div class="left_tab">
        <div
          v-for="(item, index) in tab_list"
          :key="index"
          :class="{ isChoose: isChoose == index }"
          @click="showTab(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="right_content_parent">
      <div class="right_content">
        <component
          :is="componestsList[isChoose]"
          v-if="forceUpdate"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import process from "@/assets/image/science/process.png";
export default {
  name: "SciencePolicy",
  mounted() {
    this.changeHeight();
  },
  components: {
    SciencePolicyCom: () =>
      import("@/components/science/SciencePolicy/SciencePolicyCom.vue"),
    EnterpriseProjectLibrary: () =>
      import("@/components/science/SciencePolicy/EnterpriseProjectLibrary.vue"),
  },
  data() {
    return {
      forceUpdate: true,
      xmmc: "",
      sbmc: "",
      xmzt: "",
      componestsList: ["SciencePolicyCom", "EnterpriseProjectLibrary"],
      tab_height: 0,
      process: process,
      tab_list: ["科技政策", "企业项目库"],
      isChoose: 0,
      contentIndex: 0,
      keyword: "",
      search: "",
      columns12: [
        {
          title: "Name",
          slot: "name",
        },
        {
          title: "Age",
          key: "age",
        },
        {
          title: "Address",
          key: "address",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      data6: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jim Green",
          age: 24,
          address: "London No. 1 Lake Park",
        },
        {
          name: "Joe Black",
          age: 30,
          address: "Sydney No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
      ],
      data7: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
      ],
    };
  },
  methods: {
    changeHeight() {
      let that = this;
      this.$nextTick(() => {
        that.tab_height =
            Number($(".right_content_parent").height()) >
            Number(document.body.clientHeight) - 101
                ? $(".right_content_parent").height() + "px"
                : Number(document.body.clientHeight) - 101 + "px";
      });
    },
    showTab(index) {
      this.forceUpdate = false;
      this.isChoose = index;
      this.$nextTick(() => {
        this.forceUpdate = true;
      });
      this.changeHeight();
    },
    handleUpload(file, name) {
      this.fillForm[name] = file;
    },
  },
};
</script>

<style scoped lang="scss">
.operation_span {
  color: black;
}
.right_real_content .ivu-btn {
  border: none;
  color: #1767E0;
}
.ivu-select {
  height: 40px !important;
}
.ivu-upload {
  display: inline-block;
  vertical-align: top;
  height: 40px;
  margin-left: -10px;
}

.ivu-btn {
  background-color: rgb(227, 229, 235);
  height: 40px;
  outline: none;
  border: 0 none;
}

.preview {
  background-color: rgb(227, 229, 235);
  cursor: pointer;
  padding: 8px 0 0 0;
  width: 30%;
  margin-left: -10px;
  vertical-align: top;
  text-align: center;
}

.ivu-input-wrapper {
  width: 80%;
}

.neirong {
  margin-left: 130px;
  .download_span {
    color: blue;
    display: inline-block;
    margin-right: 30px;
  }
  p {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}

.common_liucheng:nth-child(1) {
  border: none;
}

.common_liucheng {
  border-top: 2px dotted #f5f5f5;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 60px;
    font-weight: bold;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;
  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  background-color: #1767E0;
  z-index: 51;
  text-align: center;
  font-size: 14px;
  color: white;
  padding: 0 27px;
  line-height: 32px;
  height: 32px;
  border-radius: 4px;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.isChoose {
  color: #37c5cf;
}

.right_content {
  width: 90%;
  border-width: 1px;
  border-color: rgb(218, 226, 229);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  min-width: 900px;
  height: auto;
}

.right_content_parent {
  box-sizing: border-box;
  width: 80%;
  min-width: 960px;
  float: left;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  position: relative;
}

.left_tab_parent {
  float: left;
  min-width: 240px;
  width: 20%;
  background-color: rgb(250, 250, 252);
  .left_tab {
    margin-top: 60px;
    div {
      width: 210px;
      margin: 0 auto;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      box-sizing: border-box;
      padding-left: 31px;
      margin-bottom: 20px;
      border-width: 1px;
      border-color: rgb(235, 235, 235);
      border-style: solid;
      background-color: rgb(255, 255, 255);
      cursor: pointer;
    }
  }
}
</style>
